:root {
  --primary-color: #1a2b4c;
  --primary-color-0-1: rgba(26, 43, 77, 0.1);
  --primary-color-0-2: rgba(26, 43, 77, 0.2);
  --info-color: #9999cc;

  --accent-color-1-2: #96033c;
  --accent-color: #aa0746;
  --accent-color-0-6: #aa0745c8;
  --accent-color-0-2: rgb(170, 7, 70, 0.2);

  --warning-color: rgb(255, 204, 51);
  --warning-color-0-6: rgba(255, 204, 51, 0.6);
  --warning-color-0-2: rgba(255, 204, 51, 0.2);

  --error-color: #ff3333;
  --error-color-0-2: rgba(255, 51, 51, 0.2);

  --success-color: #66cc33;
  --success-color-0-2: rgba(102, 204, 51, 0.2);
}
