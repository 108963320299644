.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.mat-padding,
.mat-padding-1 {
  padding: 16px;
}

.mat-padding-2 {
  padding: 32px;

  @media screen and (max-width: 500px) {
    padding: 16px;
  }
}

.f-width {
  width: 100%;
}

.hide,
.hidden {
  display: none !important;
}

.font-justify {
  text-align: justify;
}

.m-0 {
  margin: 0 !important;
}

.underline {
  text-decoration: underline;
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.bold {
  font-weight: bold !important;
}

.relative {
  position: relative;
}

.block {
  display: block !important;
}

.rounded {
  border-radius: 4px;
}

.pointer {
  cursor: pointer;
}

.card {
  border-radius: 4px;
  background-color: white;
  border: 1px solid #b0bac9;
}

.layout-padding > * {
  padding: 8px;
}

.checkbox-layout {
  padding-top: 38px;
}

.ant-col-flex {
  flex: 1 1 auto;
}

.ant-col-percentage {
  width: 128px;
}
