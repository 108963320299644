/** elevation override **/

.mat-elevation-z1 {
  box-shadow: 0px 1px 1px 1px #b2b8b945 !important;
}

.mat-elevation-z2 {
  box-shadow: 1px 1px 2px 1px #b2b8b945 !important;
}

.mat-elevation-z3 {
  box-shadow: 2px 2px 4px 1px #b2b8b945 !important;
}

.mat-elevation-z4 {
  box-shadow: 2px 2px 4px 2px #b2b8b945 !important;
}

.mat-elevation-z5 {
  box-shadow: 3px 3px 4px 2px #b2b8b945 !important;
}
