// @import './styles/resets.scss';
@import './styles/theme.scss';
@import './styles/utilies.scss';
@import './styles/colors.scss';
@import './styles/elevations.scss';
@import './styles/extras.scss';

.ant-input-number {
  width: 100% !important;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging .example-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.ant-btn-primary.accent {
  border-color: var(--accent-color);
  background: var(--accent-color);

  &:hover,
  &:focus {
    color: #fff;
    border-color: var(--accent-color-0-6);
    background: var(--accent-color-0-6);
  }

  &:active {
    color: #fff;
    border-color: var(--accent-color-1-2);
    background: var(--accent-color-1-2);
  }
}

// Used for the select input with clear button
.ant-col-hardcoded-1 {
  width: calc(100% - 127px);
}

.ant-col-fourth {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

.ant-col-third {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }

  @media (min-width: 992px) {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

.ant-col-twice {
  display: block;
  flex: 0 0 100%;
  max-width: 100%;

  @media (min-width: 576px) {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

// because of ck editor, we remove margin from p block
.ckeditor p {
  margin: 0;
}

.ant-form .mat-h3 {
  color: black;
  margin-bottom: 0;
}

.ant-collapse-content > .ant-collapse-content-box {
  padding: 0 16px;
}

.fieldset {
  border: 1px solid #b0bac9;
  border-radius: 8px;
}
