@import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
@import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

// Full calendar colors
.fc-col-header-cell-cushion,
.fc-daygrid-day-number {
  color: var(--primary-color);
}

.fc-daygrid-dot-event,
.fc-daygrid-event-dot {
  color: var(--accent-color-1-2);
  --fc-event-border-color: var(--accent-color-1-2);
}

// AG grid

ag-grid-angular *::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 8px;
  height: 8px;
}

ag-grid-angular *::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.ag-body-viewport-wrapper.ag-layout-normal {
  overflow-x: scroll;
  overflow-y: scroll;
}

.ag-body-horizontal-scroll.ag-scrollbar-invisible {
  display: flex !important;
}

.low-extra-padding {
  .ant-form-item {
    margin-bottom: 0;
  }
}
